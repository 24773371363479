import '../scss/app.scss';

/* Your JS Code goes here */
import { Tooltip, Toast, Popover } from 'bootstrap';
import { Fancybox } from '@fancyapps/ui';

import './component.js';

/* Demo JS */
import './demo.js';
