import Swiper from 'swiper/bundle';

/**
 * SCROLL
 */
$(document).ready(function () {
    $(".navbar").on("click", ".navbar-brand", function (event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 800);
    });
});

/**
 * SLIDER
*/
const swiper = new Swiper(".swiper", {
    loop: false,
    slidesPerView: 3,
    initialSlide: 1,
    centeredSlides: true,
    spaceBetween: 100,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 100
        },
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});

/**
 * SLIDER ARROW
 */
function calcArrow() {
    const marginPart = 50; // full = 100
    const arrowWidthPart = 14; // full = 28
    const arrowPos = (($( window ).width() - $( ".swiper-slide-img" ).width()) / 2) - marginPart - arrowWidthPart;
    $( ".swiper-button-prev" ).css("left", arrowPos);
    $( ".swiper-button-next" ).css("right", arrowPos);
}
calcArrow();
$( window ).resize(function() {
    setTimeout(calcArrow, 50);
});